<template>
  <b-card no-body>
    <b-card-body>
      <div class="d-flex justify-content-between flex-wrap">

        <!-- Sorting  -->
        <b-form-group
          label="Sort"
          label-size="sm"
          label-align-sm="left"
          label-cols-sm="1"
          label-for="sortBySelect"
          label-class="mt-50 mr-50 pl-0"
          class="mb-0 text-nowrap"
          style="min-width: 20rem"
        >
          <b-input-group
            size="sm"
          >
            <b-form-select
              id="sortBySelect"
              v-model="sortBy"
              :options="sortOptions"
            >
              <template #first>
                <option value="">
                  none
                </option>
              </template>
            </b-form-select>
            <b-form-select
              v-model="sortDesc"
              size="sm"
              :disabled="!sortBy"
            >
              <option :value="false">
                Asc
              </option>
              <option :value="true">
                Desc
              </option>
            </b-form-select>
          </b-input-group>
        </b-form-group>
        <!-- ./Sorting  -->

        <!-- Search -->
        <b-form-group
          label="Search"
          label-cols-sm="3"
          label-align-sm="right"
          label-size="sm"
          label-for="filterInput"
          label-class="mt-50"
          class="mb-0"
          style="width: 30rem"
        >
          <b-input-group size="sm">
            <b-form-input
              id="filterInput"
              v-model="filter"
              type="search"
              placeholder="Type to Search"
            />
            <b-input-group-append>
              <b-button
                :disabled="!filter"
                @click="filter = ''"
              >
                Clear
              </b-button>
            </b-input-group-append>
          </b-input-group>
        </b-form-group>
        <!-- ./Search -->
      </div>
    </b-card-body>

    <b-table
      responsive
      striped
      hover
      show-empty
      class="position-relative"
      :fields="fields"
      :items="items"
      :per-page="perPage"
      :current-page="currentPage"
      :filter="filter"
      :filter-included-fields="filterOn"
      :busy="isLoading"
      @filtered="onFiltered"
      @row-clicked="openReview"
    >
      <template #cell(specification_name)="data">
        <p class="mb-0 text-lg-center align-middle">
          {{ data.value }}
        </p>
      </template>

      <template #cell(status)="data">
        <p v-if="data.value === 'CLOSED_ARCHIVED'" class="mb-0 text-lg-center align-middle">
          CLOSED AND ARCHIVED
        </p>
        <p v-else class="mb-0 text-lg-center align-middle">
          {{ data.value }}
        </p>
      </template>

      <template #cell(reviewers)="data">
        <b-badge
          v-for="item in data.value"
          :key="item"
          v-b-tooltip.hover.top="item"
          variant="light-primary"
          class="text-capitalize mx-25"
        >
          {{ item.split('@')[0] }}
        </b-badge>
      </template>
    </b-table>

    <b-card-body class="d-flex justify-content-between flex-wrap pt-0">
      <!-- Page length -->
      <b-form-group
        label="Per Page"
        label-cols="6"
        label-align="left"
        label-size="sm"
        label-class="mt-50"
        label-for="sortBySelect"
        class="text-nowrap mb-md-0 mr-1"
      >
        <b-form-select
          id="perPageSelect"
          v-model="perPage"
          size="sm"
          inline
          :options="pageOptions"
        />
      </b-form-group>
      <!-- ./Page length -->

      <!-- Pagination -->
      <div>
        <b-pagination
          v-model="currentPage"
          :total-rows="totalRows"
          :per-page="perPage"
          first-number
          last-number
          prev-class="prev-item"
          next-class="next-item"
          class="mb-0"
        >
          <template #prev-text>
            <feather-icon
              icon="ChevronLeftIcon"
              size="18"
            />
          </template>
          <template #next-text>
            <feather-icon
              icon="ChevronRightIcon"
              size="18"
            />
          </template>
        </b-pagination>
      </div>
      <!-- ./Pagination -->
    </b-card-body>
  </b-card>
</template>

<script>
import { BPagination, BTable } from 'bootstrap-vue'

export default {
  name: 'ReviewsTable',
  components: {
    BTable,
    BPagination,
  },
  props: {
    items: {
      type: Array,
      default: () => [],
    },
    isLoading: {
      required: false,
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    perPage: 5,
    pageOptions: [3, 5, 10],
    totalRows: 1,
    currentPage: 1,
    sortBy: '',
    sortDesc: false,
    sortDirection: 'asc',
    filter: null,
    filterOn: [],
    showArchived: false,
  }),
  computed: {
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter(f => f.sortable)
        .map(f => ({ text: f.label, value: f.key }))
    },
    fields() {
      return [
        { key: 'status', label: 'Status', sortable: true },
        { key: 'specification_name', label: 'Specification', sortable: true },
        {
          key: 'reviewers',
          label: 'Reviewers',
          sortable: true,
          filterByFormatted: true,
          sortByFormatted: true,
          formatter: this.mapReviewers,
        },
      ]
    },
  },
  watch: {
    items: {
      immediate: true,
      handler(newVal) {
        // Set the initial number of items
        this.totalRows = newVal.length
      },
    },
  },
  methods: {
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length
      this.currentPage = 1
    },
    openReview(row) {
      this.$store.commit('app/SET_DYNAMIC_PAGE_TITLE', row.specification_name)
      this.$router.push({
        name: 'specification_page',
        params: {
          modelId: sessionStorage.getItem('kompozition-workspace'),
          specId: row.specification_id,
        },
      })
    },
    mapReviewers(reviewers) {
      // converts list of reviewers (user ids) to string containing all reviewer emails
      return reviewers.map(r => this.getUserUsername(r.reviewer))
    },
  },
}
</script>

<style lang="scss">
@import 'src/@core/scss/vue/libs/vue-select.scss';

tr {
  min-height: 3rem !important;
  height: 3rem !important;
}

th {
  vertical-align: middle !important;
}

.table.b-table > tbody .b-table-row-selected.table-active td {
  background-color: unset;
}

.table.b-table.table-hover > tbody > tr.table-active:hover td{
  //background-color: unset;
  background-image: none;
}

.table.b-table > tbody > .table-active {
  background-color: unset;
}

// Center align all table-headers so the status and reviewers headings don't look odd
th {
  text-align: center !important;
}
</style>
